import { createRouter, createWebHistory } from 'vue-router'
import MenuDesign from '@/views/menuDesignView'
// import MenuDesign from '@/views/nestableDesignView'
import LiveView from '@/views/liveView'
import ActionLogs from '@/views/actionLogView'
import CreateApp from '@/views/createApp'
import AccountProfile from '../views/accountProfile.vue'
import ProvisionShortCodeExtension from '@/views/provisionExtension'
import ShortCodeProviders from '@/views/shortCodeProviders'
import BuyShortCode from '@/views/buyShortCode'
import ConnectAppToShortCode from '@/views/connectAppToShortcode'
import MyShortCodes from '@/views/myShortCodes'
import AppList from '@/views/appsList'
import AppTemplates from '@/views/appTemplates'
import AccountBilling from '@/views/accountBilling'
import AppSettings from '@/views/editAppSettings'
import CompleteSession from '@/views/completeSession'
import UncompleteSession from '@/views/uncompleteSession'
import Search from '@/views/search'
import Emulator from '@/views/emulatorView'
import EmulatorForTemplates from '@/views/emulatorForTemplates'
import Dashboard from '@/views/dashboard.vue'
import Home from '@/views/Home.vue'
import FAQ from '@/views/faq.vue'
import About from '@/views/About.vue'
import Contact from '@/views/contact.vue'
import Pricing from '@/views/pricing.vue'
import getStarted from '@/components/get-started.vue'

const routes = [
  {
    path: '/',
   
    component: () => import('../layouts/websiteLayout.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
    ],
  },
  {
    path: '/faq',
    component: () => import('../layouts/websiteLayout.vue'),
    children: [
      {
        path: '',
        name: 'Faq',
        component: FAQ,
      },
    ],
  },
  {
    path: '/pricing',
   
    component: () => import('../layouts/websiteLayout.vue'),
    children: [
      {
        path: '',
        name: 'Pricing',
        component: Pricing,
      },
    ],
  },
  {
    path: '/about',
   
    component: () => import('../layouts/websiteLayout.vue'),
    children: [
      {
        path: '',
        name: 'About',
        component: About,
      },
    ],
  },
  {
    path: '/contact',
   
    component: () => import('../layouts/websiteLayout.vue'),
    children: [
      {
        path: '',
        name: 'Contact',
        component: Contact,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/forgot_password.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('../views/reset_password.vue')
  },

  {
    path: '/app/design/:id',  
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'appdesign',
        component: MenuDesign,
      },
    ],
  },
  {
    path: '/account-profile',
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'AccountProfile',
        component: AccountProfile,
      },
    ],
  },
  {
    path: '/create-app',
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'CreateApp',
        component: CreateApp,
      },
    ],
  },
  {
    path: '/buy-shortcode',
   
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'BuyShortCode',
        component: BuyShortCode,
      },
    ],
  },
  {
    path: '/provision-shortcode',
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'ProvisionShortCode',
        component: ProvisionShortCodeExtension,
      },
    ],
  },
  {
    path: '/shortcode-providers',
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'ShortCodeProviders',
        component: ShortCodeProviders,
      },
    ],
  },

  {
    path: '/my-shortcodes',
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'MyShortCodes',
        component: MyShortCodes,
      },
    ],
  },
  {
    path: '/connect-shortcode/:id',
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'ConnectAppToShortCode',
        component: ConnectAppToShortCode,
      },
    ],
  },
  {
    path: `/app/liveview/:id`,
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'LiveView',
        component: LiveView,
      },
    ],
  },
  {
    path: `/app/actionlogs/:id`,
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'ActionLogs',
        component: ActionLogs,
      },
    ],
  },
  {
    path: `/app/completesession/:id`,
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'CompletedSession',
        component: CompleteSession,
      },
    ],
  },
  {
    path: `/app/uncompletedsession/:id`,
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'UncompletedSession',
        component: UncompleteSession,
      },
    ],
  },

  {
    path: '/app-settings/:id',
   
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'Settings',
        component: AppSettings,
      },
    ],
  },
  {
    path: '/app-list',
   
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'AppList',
        component: AppList,
      },
    ],
  },
  {
    path: '/app-templates',
   
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'AppTemplates',
        component: AppTemplates,
      },
    ],
  },
  {
    path: '/account-billing',
   
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'AccountBilling',
        component: AccountBilling,
      },
    ],
  },
  {
    path: '/app/search/:id',
    
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'Search',
        component: Search,
      }
    ]
  },
  {
    path: '/app/dashboard/:appId',
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
      }
    ]
  },
  {
    path: '/get-started',
    
    component: () => import('../layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'GetStarted',
        component: getStarted,
      }
    ]
  },
  {
    path: '/app/emulator/:appId',
    name: 'Emulator',
    component: Emulator
  },
  {
    path: '/apptemplate/emulator/:appId',
    name: 'EmulatorForTemplates',
    component: EmulatorForTemplates
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

