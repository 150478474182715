<template>
  <div>
    <div class="row">
      <div class="col-md-12 grid-margin">
        <div class="row">
          <div class="col-8 mb-0 mb-xl-0">
            <h4 class="font-weight-bold">List of USSD Apps</h4>
          </div>
          <div class="col-4">
            <router-link class="btn btn-primary btn-icon-text text-white float-right" to="/create-app">
              <span class="h4 text-white">New App </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table id="order-listing1" class="table">
        <thead>
          <tr>
            <th>App Name</th>
            <th>Shortcode</th>
            <th>Template Tag</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in tableData" :key="data.appId">
            <td>{{ data.appName }}</td>
            <td>{{ data.shortCode }}</td>
            <td>
              <div class="form-check form-check-flat form-check-info">
                <label class="form-check-label">
                  <input type="checkbox" disabled class="form-check-input" v-model="data.useTemplateTag" />
                  <i class="input-helper"></i></label>
              </div>
            </td>
            <td>
              <router-link class="btn btn-outline-success ml-2 design" :to="'/app/design/' + data.appId"
                data-toggle="tooltip" title="Open Designer" data-placement="top" data-content="Design your app"
                data-intro="Hello step one!">
                <i class="fa fa-external-link" tooltip="Open Designer"></i>
              </router-link>
              <router-link class="btn btn-outline-primary ml-2 appsettings" :to="'/app-settings/' + data.appId"
                data-toggle="tooltip" title="App Settings">
                <i class="fa fa-cogs"></i>
              </router-link>
              <button @click="cloneAppClicked(data)" class="btn btn-outline-warning ml-2 cloneapp"
                data-bs-toggle="tooltip" data-toggle="tooltip" title="Clone App">
                <i class="fa fa-clone"></i>
              </button>
              <button class="btn btn-outline-danger ml-2 deleteapp" @click="deleteApp(data.appId)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <div class="modal fade" id="duplicateAppModal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="ModalLabel">
                  Clone App : {{ cloneAppData.appName }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label   class="col-form-label">App Name:</label>
                    <input type="text" class="form-control" v-model="cloneAppData.appName" />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Description:
                    </label>
                    <input type="text" class="form-control" v-model="cloneAppData.description" />
                  </div>
                  <div class="form-group">
                    <label  class="col-form-label">Short Code:</label>
                    <input type="text" class="form-control" v-model="cloneAppData.shortCode" />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-success text-white" @click="cloneAppSubmitted()">
                  Clone App
                </button>
                <button type="button" class="btn btn-light" @click="closeModal()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import baseUrl from "@/shared/baseUrl";
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";
import { appTourMixin } from "@/shared/vueTourMixins";
// import Shepherd from "shepherd.js";
export default {
  name: "CreateApp",
  components: {

  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "my-tour",
  mixins: [appTourMixin],
  data() {
    return {
      createAppData: {
        appName: "",
        description: "",
        useTemplateTag: "",
        shortCode: "",
      },
      cloneAppData: {},
      tableData: [],
      errors: {},
    };
  },
  methods: {
    getApp() {
      api
        .getAllApps()
        .then((response) => {

           if(response.error){
            console.log("Going to login")
            this.$router.push("/login");
            return;
          }
          this.tableData = response.data;
          if (this.tableData.length == 0) {
            this.$router.push("/get-started");
          }
        })
        .catch((error) => {
          console.log("Error", error);
          this.errors = error.data;
        });
    },
    closeModal() {
      jQuery("#duplicateAppModal").modal("hide");
    },
    cloneAppClicked(data) {
      this.cloneAppData = {};
      this.cloneAppData.appId = data.appId;
      this.cloneAppData.appName = data.appName;
      this.cloneAppData.description = data.description;
      this.cloneAppData.useTemplateTag = data.useTemplateTag;
      this.cloneAppData.shortCode = data.shortCode;
      jQuery("#duplicateAppModal").modal("show");
    },
    cloneAppSubmitted() {
      let payload = Object.assign({}, this.cloneAppData);
      const vm = this;
      api
        .cloneApp(payload)
        .then((response) => {
          vm.cloneAppData = {};
          if (response.status == "00") {
            this.toast.success(response.message);
          } else {
            this.toast.error(response.message);
          }
          this.getApp();
          jQuery("#duplicateAppModal").modal("hide");
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    deleteApp(id) {
      //prompt user to confjirm delete
      if (confirm("Are you sure you want to delete this app?")) {
        const token = localStorage.getItem("token");
        baseUrl
          .get("/deleteApp/" + id, {
            headers: { Authorization: "Bearer " + token },
          })
          .then((response) => {
            this.getApp();
            console.log;
            this.successMessage = response.data.message;
            this.toast(response.data.message, {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.toast(error.message, {
              type: "error",
              position: "top-right",
              duration: 3000,
            });
          });
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getApp();
    // this.$tours['myTour'].start()
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    } else {
      this.$router.push("/app-list");
    }
    //this.mountTourSteps();
  },
};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}
</style>
