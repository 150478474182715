<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card" style="margin-top:50px">
          <div class="card-body">
            <div class="container text-center pt-5">
              
              <div class="row pt-4">
                  <div class="col">
                    <h2 class="font-weight-normal mb-2 text-center">Plans for Using USSD App Designing</h2>
                    <h4 class="mb-2 text-center">These prices are for app design only they do not DO NOT come with a shortcode</h4>
                    <p class="w-75 mx-auto mb-2">Choose a plan that suits you the best.</p>   
                  </div>
                </div>
              



              <div class="row pricing-table">
                <!-- Pricing Cards -->
                <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card" v-for="plan in plans" :key="plan.name">
                  <div class="card border-primary border pricing-card-body" :class="[plan.borderClass, 'pricing-card-body']">
                    <div class="text-center pricing-card-head">
                      <h3 :class="plan.textClass">{{ plan.title }}</h3>
                      <h2 class="font-weight-normal mb-4">{{ plan.price  }}</h2>
                    </div>
                    <ul class="list-unstyled plan-features">
                      <li v-for="feature in plan.features" :key="feature">{{ feature }}</li>
                    </ul>
                    <div class="wrapper">
                      <router-link to="/account-billing" class="btn btn-outline-primary btn-block">Start Now</router-link>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                  <div class="card border border-primary pricing-card-body">
                    <div class="text-center pricing-card-head">
                      <h3>Enterprise Deploment</h3>
                     </div>
                    <ul class="list-unstyled plan-features">
                      <li>Unlimited Menus</li>
                      <li>Unlimited sessions Monthly</li>
                      <li>Api Calls support</li>
                      <li>Bulk Sms (3000 sms monthly)</li>
                      <li>Connect Any external shortcode</li>
                       <li>Application Support and development</li>
                    </ul>
                    <div class="wrapper">
                      <router-link to="/contact" class="btn btn-outline-primary btn-block">Contact Us</router-link>
                    </div>
                  </div>
                </div>


                
              </div>



               <div class="row pt-4">
                  <div class="col">
                    <h2 class="font-weight-normal mb-2 text-center">Short Code Pricing By Country</h2>
                    <h4 class="text-center">ShortCodes are provisioned within 24 hours after payment</h4>
                  </div>
                </div>
                <div class="row pricing-table">
                   <ShortCodePricing/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShortCodePricing from "@/components/shortCodePricing.vue";
export default {
  components: {
    ShortCodePricing
  },
  data() {
    return {
      isAnnual: true, // Toggle state
      plans: [
    
        {
          name: 'BASIC-3',
          title : 'Basic Plan - 3 months',
          price: '$50',
          features: ['2 Ussd Apps', '50 Screen Menus', 'Max 30,000 Sessions Monthly', 'Api Calls support', 'Bulk Sms (1000 free sms)', 'Connect Any external shortcode'],
          borderClass: 'border-primary',
          btnBorder:'btn-outline-primary',
          textClass: ''
        },
        {
          name: 'BASIC-6',
          title : 'Basic Plan - 6 Months ',
          price: '$85',
          features: ['2 Ussd Apps', '50 Screen Menus', 'Max 30,000 Sessions Monthly', 'Api Calls support', 'Bulk Sms (1000 free sms)', 'Connect Any external shortcode'],
          borderClass: 'border-primary',
          btnBorder:'btn-outline-primary',
          textClass: ''
        },
        {
          name: 'STANDARD',
          title:'Standard Plan - 1 Year',
          price: '$150',
          features: ['10 Ussd Apps', 'Unlimited Menus', 'Max 50,000 Sessions Monthly', 'Api Calls support', 'Bulk Sms (2000 sms monthly)', 'Connect Any external shortcode', 'Application Support and development'],
          borderClass: 'border-success',
          btnBorder:'btn-outline-success',
          textClass: 'text-success'
        },
        {
          name: 'PREMIUM',
          title: 'Premium - 1 Year',
          price: '$250',
          features: ['Unlimited Menus', 'Max 100,000 Sessions Monthly', 'Api Calls support', 'Bulk Sms (3000 sms monthly)', 'Connect Any external shortcode',  'Application Support and development'],
          borderClass: 'border-primary',
          btnBorder:'btn-outline-primary',
          textClass: ''
        },
        
      ]
    };
  },
  methods: {
    togglePlan() {
      this.isAnnual = !this.isAnnual; // Toggle the plan type
    }
  }
};
</script>
