<template>
  <div class="card">
    <div class="card-header">
      <h4 style="padding: 10px">ShortCode Providers</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 col-sm-12"></div>
        <div class="col-md-9 col-sm-12">
          <form>
            <button
              type="button"
              class="btn btn-primary mt-0 text-white float-right"
              @click="openCreateExtensionModal()">
              New Provider
            </button>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12"></div>
        <div class="col-md-12 col-sm-12">
          <div class="form-group row mt-5" v-if="shortCodeProviders.length">
            <h4>Available Shortcode Providers</h4>
            <div class="table-responsive">
              <table id="order-listing" class="table">
                <thead>
                  <tr>
                    <th class="col-sm-2">Provider Name </th>
                    <th class="col-sm-2">Provider ID</th>
                    <th class="col-sm-6">Provider Url </th>
                    <th class="col-sm-2"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in shortCodeProviders" :key="data.uuid">
                    <td>
                      {{ data.providerName }}
                    </td>
                    <td>{{ data.providerId }}</td>
                    <td>{{ data.providerUrl }}</td>
                     <td>
                      <button
                        type="button"
                        class="btn btn-danger ml-2 design"
                        @click="deleteProvider(data)"
                        title="Delete Provider">
                        <i class="fa fa-trash" tooltip="Delete"></i> Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group" v-if="shortCodeProviders.length == 0">
            <div class="card card-inverse-info">
              <div class="card-body">
                <div class="card-title font-weight-bold">No Providers</div>
                <div class="card-text">
                  <div class="row">
                    <div class="col-1">
                      <div class="badge badge-pill badge-outline-info">
                        <i class="fas fa-info-circle"></i>
                      </div>
                    </div>
                    <div class="col-11">
                      <span class="small">
                        There are no ShortCode Providers
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      
      <div
        class="modal fade"
        id="createShortCodeExtension"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">
                New ShortCode Provider
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="forms-sample">
                <div class="form-group row" v-if="errorMsg.length">
                  <div class="col-12">
                    <div
                      class="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong v-bind:key="err" v-for="err in errorMsg">{{
                        err
                      }}</strong>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label"
                    >Provider Name</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="MTN Ghana"
                      v-model="provider.providerName"
                    />
                  </div>
                </div>
                
                <div class="form-group row">
                  <label class="col-form-label"
                    >Enter ProviderId to be used in creating translator name E.g. mtngh,
                    airtelgh,vodagh</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="mtngh"
                      v-model="provider.providerId"
                    />
                  </div>
                </div>
                
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success text-white"
                @click="registerShortCodeProvider()"
              >
                Submit
              </button>
              <button
                type="button"
                class="btn btn-light"
                @click="closeCreateExtensionModal()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="prodgressDialog"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="loader-demo-box1">
              <div class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";

function openInNewTab(url) {
  window.open(url, "_blank", "noreferrer");
}
export default {
  name: "ProvisionShortCodeExtension",
  components: {},
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      provider :{
        providerName: "",
        providerId: "",
      },
      errorMsg: [],
      shortCodeProviders: [],
    };
  },
  methods: {
    openCreateExtensionModal() {
      jQuery("#createShortCodeExtension").modal("show");
    },
    closeCreateExtensionModal() {
      jQuery("#createShortCodeExtension").modal("hide");
    },
    registerShortCodeProvider() {
      if (!this.validateFields()) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      let payload = {
        providerName: this.provider.providerName,
        providerId: this.provider.providerId,
      };
      jQuery("#createShortCodeExtension").modal("hide");
       api
        .doPost("/api/registerProvider", payload)
        .then((response) => {
           if (response.status == "00") {
            this.toast.success(response.message);
            this.getShortCodeProviders();
          } else {
            this.toast.info(response.message);
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    openProvisionModal(data) {
      this.provider = {
        providerName: "",
        providerId: "",
      };
      jQuery("#provisionShortCodeModal").modal("show");
    },
    closeProvisionModal() {
      jQuery("#provisionShortCodeModal").modal("hide");
    },
    getShortCodeProviders() {
      let vm = this;
      api
        .doGet(`/api/getAllProviders`)
        .then((response) => {
          vm.shortCodeProviders = response;
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    deleteProvider(extension) {
      let uuid = extension.uuid;
      if (
        !confirm(`Are you sure you want to delete this provider ${extension.providerName}`)
      ) {
        return;
      }
      api
        .doGet(`/api/deleteProvider/${uuid}`)
        .then((response) => {
          if (response.status === "00") {
            this.toast.success(response.message);
            this.getShortCodeProviders();
          } else {
            this.toast.error(response.message);
            this.getShortCodeProviders();
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
          this.getShortCodeProviders();
        });
    },
    validateFields() {
      this.errorMsg = [];
      if (this.provider.providerName === "") {
        this.errorMsg.push("Enter Provider Name");
      }
      if (this.provider.providerId === "") {
        this.errorMsg.push("Enter Provider Id");
      }
      return this.errorMsg.length === 0;
    },
  },
  mounted() {
    this.getShortCodeProviders();
  },
};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}

#error {
  margin-top: 40px;
}
</style>
