<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-2">
          <ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
            <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
              <a
                class="nav-link"
                :class="{ active: activeTab === tab.id }"
                @click="setActiveTab(tab.id)"
                role="tab"
                :aria-controls="tab.contentId"
                :aria-selected="activeTab === tab.id"
              >
                {{ tab.name }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-10">
          <div class="tab-content tab-content-vertical">
            <div
              class="tab-pane fade"
              :class="{ 'active show': activeTab === 'tab-ghana' }"
              id="tab-ghana"
              role="tabpanel"
              aria-labelledby="home-tab-vertical"
            >
              <div>
                <div class="card1 mb-0 mt-0">
                  <div class="card-bodyq mb-0">
                    <h4 class="mb-4">Pricing for Ghana</h4>

                    <div class="card card-inverse-info">
                      <div class="card-body">
                        <h4 class="mb-2 text-warning">
                          SHARED SHORTCODE
                          <button
                            type="button"
                            class="btn btn-rounded btn-outline-success btn-fw float-end"
                            @click="
                              openModal('I want a Shared Shortcode for Ghana')
                            "
                          >
                            Talk to us
                          </button>
                        </h4>
                        <p></p>
                        <h5 class="mb-3 text-white">SETUP COST</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Shortcode Format</th>
                                <th>Lease Period</th>
                                <th>Cost (GHS)</th>
                                <th>Cost Per Session (GHS)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>1 Year</td>
                                <td>1700</td>
                                <td>0.005</td>
                              </tr>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>6 months</td>
                                <td>900</td>
                                <td>0.005</td>
                              </tr>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>3 months</td>
                                <td>500</td>
                                <td>0.005</td>
                              </tr>
                            </tbody>
                          </table>
                          <p></p>
                        </div>
                      </div>
                    </div>

                    <br />

                    <div class="card card-inverse-info">
                      <div class="card-body">
                        <h4 class="mb-2 text-warning">
                          DEDICATED SHORTCODE
                          <button
                            type="button"
                            class="btn btn-rounded btn-outline-success btn-fw float-end"
                            @click="
                              openModal(
                                'I want a Dedicated Shortcode for Ghana'
                              )
                            "
                          >
                            Talk to us
                          </button>
                        </h4>
                        <p></p>
                        <h5 class="mb-3 text-white">SETUP COST</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Shortcode Format</th>
                                <th>Lease Period</th>
                                <th>Cost (GHS)</th>
                                <th>Session Cost (GHS)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>MTN</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>TELECEL</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>AT (ArtelTigo)</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                            </tbody>
                          </table>
                          <p></p>

                          <h5 class="mt-4 mb-2 text-white">
                            MONTHLY MENTENANCE COST
                          </h5>
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Network</th>
                                  <th>Cost (GHS)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>MTN</td>
                                  <td>2700</td>
                                </tr>
                                <tr>
                                  <td>TELECEL</td>
                                  <td>2700</td>
                                </tr>
                                <tr>
                                  <td>AT (ArtelTigo)</td>
                                  <td>2700</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': activeTab === 'tab-nigeria' }"
              id="tab-nigeria"
              role="tabpanel"
              aria-labelledby="profile-tab-vertical"
            >
              <div>
                <div class="card1 mb-0 mt-0">
                  <div class="card-bodyq mb-0">
                    <h4 class="mb-4">Pricing for Nigeria</h4>
                    <div class="card card-inverse-info">
                      <div class="card-body">
                        <h4 class="mb-2 text-warning">
                          SHARED SHORTCODE
                          <button
                            type="button"
                            class="btn btn-rounded btn-outline-success btn-fw float-end"
                            @click="
                              openModal('I want a Shared Shortcode for Nigeria')
                            "
                          >
                            Talk to us
                          </button>
                        </h4>
                        <h5 class="mb-3 text-white">SETUP COST</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Shortcode Format</th>
                                <th>Lease Period</th>
                                <th>Cost (NGN)</th>
                                <th>Cost Per Session (NGN)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>1 Year</td>
                                <td>1700</td>
                                <td>0.005</td>
                              </tr>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>6 months</td>
                                <td>900</td>
                                <td>0.005</td>
                              </tr>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>3 months</td>
                                <td>500</td>
                                <td>0.005</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="card card-inverse-info">
                      <div class="card-body">
                        <h4 class="mb-2 text-warning">
                          DEDICATED SHORTCODE
                          <button
                            type="button"
                            class="btn btn-rounded btn-outline-success btn-fw float-end"
                            @click="
                              openModal(
                                'I want a Dedicated Shortcode for Nigeria'
                              )
                            "
                          >
                            Talk to us
                          </button>
                        </h4>
                        <h5 class="mb-3 text-white">SETUP COST</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Shortcode Format</th>
                                <th>Lease Period</th>
                                <th>Cost (NGN)</th>
                                <th>Session Cost (NGN)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>MTN</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>TELECEL</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>AT (ArtelTigo)</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                            </tbody>
                          </table>
                          <h5 class="mt-4 mb-2 text-white">
                            MONTHLY MAINTENANCE COST
                          </h5>
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Network</th>
                                  <th>Cost (NGN)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>MTN</td>
                                  <td>2700</td>
                                </tr>
                                <tr>
                                  <td>TELECEL</td>
                                  <td>2700</td>
                                </tr>
                                <tr>
                                  <td>AT (ArtelTigo)</td>
                                  <td>2700</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': activeTab === 'tab-kenya' }"
              id="tab-kenya"
              role="tabpanel"
              aria-labelledby="contact-tab-vertical"
            >
              <div>
                <div class="card1 mb-0 mt-0">
                  <div class="card-bodyq mb-0">
                    <h4 class="mb-4">Pricing for Kenya</h4>
                    <div class="card card-inverse-info">
                      <div class="card-body">
                        <h4 class="mb-2 text-warning">
                          SHARED SHORTCODE
                          <button
                            type="button"
                            class="btn btn-rounded btn-outline-success btn-fw float-end"
                            @click="
                              openModal('I want a Shared Shortcode for Kenya')
                            "
                          >
                            Talk to us
                          </button>
                        </h4>
                        <h5 class="mb-3 text-white">SETUP COST</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Shortcode Format</th>
                                <th>Lease Period</th>
                                <th>Cost (KES)</th>
                                <th>Cost Per Session (KES)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>1 Year</td>
                                <td>1700</td>
                                <td>0.005</td>
                              </tr>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>6 months</td>
                                <td>900</td>
                                <td>0.005</td>
                              </tr>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>3 months</td>
                                <td>500</td>
                                <td>0.005</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="card card-inverse-info">
                      <div class="card-body">
                        <h4 class="mb-2 text-warning">
                          DEDICATED SHORTCODE
                          <button
                            type="button"
                            class="btn btn-rounded btn-outline-success btn-fw float-end"
                            @click="
                              openModal(
                                'I want a Dedicated Shortcode for Kenya'
                              )
                            "
                          >
                            Talk to us
                          </button>
                        </h4>
                        <h5 class="mb-3 text-white">SETUP COST</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Shortcode Format</th>
                                <th>Lease Period</th>
                                <th>Cost (KES)</th>
                                <th>Session Cost (KES)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>MTN</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>TELECEL</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>AT (ArtelTigo)</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                            </tbody>
                          </table>
                          <h5 class="mt-4 mb-2 text-white">
                            MONTHLY MAINTENANCE COST
                          </h5>
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Network</th>
                                  <th>Cost (KES)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>MTN</td>
                                  <td>2700</td>
                                </tr>
                                <tr>
                                  <td>TELECEL</td>
                                  <td>2700</td>
                                </tr>
                                <tr>
                                  <td>AT (ArtelTigo)</td>
                                  <td>2700</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': activeTab === 'tab-eswatini' }"
              id="tab-eswatini"
              role="tabpanel"
              aria-labelledby="eswatini-tab-vertical"
            >
              <div>
                <div class="card1 mb-0 mt-0">
                  <div class="card-bodyq mb-0">
                    <h4 class="mb-4">Pricing for Eswatini</h4>
                    <div class="card card-inverse-info">
                      <div class="card-body">
                        <h4 class="mb-2 text-warning">
                          SHARED SHORTCODE
                          <button
                            type="button"
                            class="btn btn-rounded btn-outline-success btn-fw float-end"
                            @click="
                              openModal(
                                'I want a Shared Shortcode for Eswatini'
                              )
                            "
                          >
                            Talk to us
                          </button>
                        </h4>
                        <h5 class="mb-3 text-white">SETUP COST</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Shortcode Format</th>
                                <th>Lease Period</th>
                                <th>Cost (SZL)</th>
                                <th>Cost Per Session (SZL)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>1 Year</td>
                                <td>1700</td>
                                <td>0.005</td>
                              </tr>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>6 months</td>
                                <td>900</td>
                                <td>0.005</td>
                              </tr>
                              <tr>
                                <td>*920*XX#,*800*XXX#</td>
                                <td>3 months</td>
                                <td>500</td>
                                <td>0.005</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="card card-inverse-info">
                      <div class="card-body">
                        <h4 class="mb-2 text-warning">
                          DEDICATED SHORTCODE
                          <button
                            type="button"
                            class="btn btn-rounded btn-outline-success btn-fw float-end"
                            @click="
                              openModal(
                                'I want a Dedicated Shortcode for Eswatini'
                              )
                            "
                          >
                            Talk to us
                          </button>
                        </h4>
                        <h5 class="mb-3 text-white">SETUP COST</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Shortcode Format</th>
                                <th>Lease Period</th>
                                <th>Cost (SZL)</th>
                                <th>Session Cost (SZL)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>MTN</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>TELECEL</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>AT (ArtelTigo)</td>
                                <td>1 Year</td>
                                <td>3000</td>
                                <td>100</td>
                              </tr>
                            </tbody>
                          </table>
                          <h5 class="mt-4 mb-2 text-white">
                            MONTHLY MAINTENANCE COST
                          </h5>
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Network</th>
                                  <th>Cost (SZL)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>MTN</td>
                                  <td>2700</td>
                                </tr>
                                <tr>
                                  <td>TELECEL</td>
                                  <td>2700</td>
                                </tr>
                                <tr>
                                  <td>AT (ArtelTigo)</td>
                                  <td>2700</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              :class="{ 'active show': activeTab === 'tab-others' }"
              id="tab-others"
              role="tabpanel"
              aria-labelledby="eswatini-tab-vertical"
            >
              <div class="card card-inverse-info">
                <div class="card-body">
                  <h4 class="mb-2 text-warning">
                    If your country is not listed we still have you covered.
                    Chat with us to learn more
                    <button
                      type="button"
                      class="btn btn-rounded btn-outline-success btn-fw float-end"
                      @click="
                        openModal(
                          'I want a Shared Shortcode for Another Country'
                        )
                      "
                    >
                      Talk to us
                    </button>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="talkToUsModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ModalLabel">Confirm Purchase</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="col-5 mb-0">Subject:</label>
                  <input
                    type="text"
                    class="form-control col-7"
                    v-model="contact.subject"
                  />
                </div>
                <p class="text-danger" v-show="error.subject">
                  {{ error.subject }}
                </p>
              </div>
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="col-5 mb-0">Email:</label>
                  <input
                    type="email"
                    class="form-control col-7"
                    v-model="contact.email"
                  />
                </div>
                <p class="text-danger" v-show="error.email">
                  {{ error.email }}
                </p>
              </div>
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="col-5 mb-0">Mobile (Optional):</label>
                  <input
                    type="text"
                    class="form-control col-7"
                    v-model="contact.mobile"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="col-5 mb-0 float-left">Message </label>
                  <textarea
                    class="form-control col-7"
                    v-model="contact.message"
                    maxlength="500"
                  ></textarea>
                </div>
                <p class="text-danger" v-show="error.message">
                  {{ error.message }}
                </p>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success text-white"
              @click="contactUsApiCall()"
            >
              Submit
            </button>
            <button type="button" class="btn btn-light" @click="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      activeTab: "tab-ghana", // Default active tab
      tabs: [
        { id: "tab-ghana", name: "Ghana", contentId: "tab-ghana" },
        { id: "tab-nigeria", name: "Nigeria", contentId: "tab-nigeria" },
        { id: "tab-kenya", name: "Kenya", contentId: "tab-kenya" },
        { id: "tab-eswatini", name: "Eswatini", contentId: "tab-eswatini" },
        { id: "tab-others", name: "OTHERS", contentId: "tab-others" },
      ],
      contact: {
        subject: "",
      },
      error: {},
    };
  },
  methods: {
    contactUsApiCall() {
      if (this.validateFields()) {
        let payload = Object.assign(this.contact);
        api
          .doPost("/api/contactus", payload)
          .then((response) => {
            console.log("Response from server >>", response);
            if (response.status == "00") {
              this.contact = { subject: "" };
              this.toast.success(response.message);
              $("#talkToUsModal").modal("hide"); // Hide the modal
            } else {
              this.toast.error("Failed to send contact info");
            }
          })
          .catch((error) => {
            this.toast.error(error.message);
          });
      }
    },
    setActiveTab(tabId) {
      this.activeTab = tabId; // Set the active tab
    },
    openModal(subject) {
      // New method to open modal
      this.contact.subject = subject; // Set the subject
      $("#talkToUsModal").modal("show"); // Show the modal
    },
    closeModal() {
      // New method to close modal
      $("#talkToUsModal").modal("hide"); // Hide the modal
      this.resetContact(); // Optional: Reset contact fields
    },
    resetContact() {
      // Optional method to reset contact fields
      this.contact = {};
      this.contact.subject = "";
    },
    validateFields() {
      this.error = {};
      if (!this.contact.email || this.contact.email.trim() === "") {
        this.error.email = "Email is required";
      }
      if (this.contact.email && this.contact.email.indexOf("@") == -1) {
        this.error.email = "Invalid email";
      }
      if (!this.contact.subject || this.contact.subject.trim() === "") {
        this.error.subject = "Subject is required";
      }
      if (!this.contact.message || this.contact.message.trim() === "") {
        this.error.message = "Message is required";
      }
      return Object.keys(this.error).length == 0;
    },
  },
};
</script>
