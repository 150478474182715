import * as axios from 'axios';
import { API } from './config';
import { logger } from './logger';


const saveMenu = async function (payload, isUpdate) {
  let enpoint = '/api/createMenu';
  if (isUpdate) {
    enpoint = '/api/updateMenu'
  }
  try {
    logger.info('Payload for save menu>>', payload);
    const response = await axios.post(`${API}${enpoint}`, payload);
    logger.info('Response fron CreateMenu', response.data);
    return response.data;
  } catch (error) {
    logger.info('Error CreateMenu>>', error);
    const resp = {};
    return resp;
  }
};

const saveMenuOrder = async function (payload) {
  let enpoint = '/api/saveMenuOrder';
  try {
    logger.info('Payload for saveMenuOrder>>', payload);
    const response = await axios.post(`${API}${enpoint}`, payload);
    logger.info('Response fron saveMenuOrder', response.data);
    return response.data;
  } catch (error) {
    logger.info('Error CreateMenu>>', error);
    const resp = {};
    return resp;
  }
};

const deleteMenuByUniqueId = async function (menuId) {
  let enpoint = '/api/deleteMenu/' + menuId;
  try {
    logger.info('Payload for deleteMenuByUniqueId>>');
    const response = await axios.get(`${API}${enpoint}`);
    return response.data;
  } catch (error) {
    logger.info('Error deleteMenuByUniqueId>>', error);
    const resp = {};
    return resp;
  }
};

const deployChanges = async function (appId) {
  let enpoint = '/api/deployChanges/' + appId;
  try {
    const response = await axios.get(`${API}${enpoint}`);
    logger.info('Response fron deployChanges', response.data);
    return response.data;
  } catch (error) {
    logger.info('Error CreateMenu>>', error);
    const resp = {};
    return resp;
  }
};


const getAppById = async function (appId) {
  let enpoint = '/api/getAppByAppId/' + appId;
  try {
    const response = await axios.get(`${API}${enpoint}`);
    logger.info('Response fron getAppById', response.data);
    return response.data;
  } catch (error) {
    logger.info('Error getAppById>>', error);
    const resp = {};
    return resp;
  }
};

const getAllMenus = async function (appId) {
  let enpoint = '/api/getAllMenus/' + appId;
  try {
    const response = await axios.get(`${API}${enpoint}`);
    logger.info('Response fron getAllMenus', response.data);
    return response.data;
  } catch (error) {
    logger.info('Error getAllMenus>>', error);
    const resp = [];
    return resp;
  }
};

const getAllMenusLite = async function (appId) {
  let enpoint = '/api/getAllMenusLite/' + appId;
  console.log('The endpoint', appId);
  try {
    const response = await axios.get(`${API}${enpoint}`);
    logger.info('Response fron getAllMenus', response.data);
    return response.data;
  } catch (error) {
    logger.info('Error getAllMenus>>', error);
    const resp = []
    return resp;
  }
};


const getDashboardData = async function (appId) {
  let enpoint = '/api/dashboard/appDashboard/' + appId;
  console.log('The endpoint', appId);
  try {
    const response = await axios.get(`${API}${enpoint}`);
    logger.info('Response fron getDashboardData', response.data);
    return response.data;
  } catch (error) {
    logger.info('Error getDashboardData>>', error);
    const resp = {}
    return resp;
  }
};

const getAllApps = async function () {
  let enpoint = '/api/getAllApps';
  try {
    let config = getAuthHeaders();
    const response = await axios.get(`${API}${enpoint}`, config);
    console.log("The response status from applist >>",response.status);
    return response.data;
  } catch (error) {
    console.log("The response errorr from applist>>",error);
    const resp = {}
    resp.error= error;
    return resp;
  }
};

const createApp = async function (payload) {
  let enpoint = '/api/createApp';
  try {
    let config = getAuthHeaders();
    const response = await axios.post(`${API}${enpoint}`, payload, config);
    return response.data;
  } catch (error) {
    const resp = {};
    return resp;
  }
};

const updateApp = async function (payload) {
  let enpoint = '/api/updateApp';
  try {
    let config = getAuthHeaders();
    const response = await axios.post(`${API}${enpoint}`, payload, config);
    return response.data;
  } catch (error) {
    const resp = {};
    return resp;
  }
};

const cloneApp = async function (payload) {
  let enpoint = '/api/cloneApp';
  try {
    let config = getAuthHeaders();
    const response = await axios.post(`${API}${enpoint}`, payload, config);
    return response.data;
  } catch (error) {
    const resp = {};
    return resp;
  }
};


const doGet = async function (endpoint) {
  try {
    let config = getAuthHeaders();
    const response = await axios.get(`${API}${endpoint}`, config);
    return response.data;
  } catch (error) {
    const resp = {};
    return resp;
  }
};

const doPost = async function (endpoint, payload) {
  try {
    let config = getAuthHeaders();
    const response = await axios.post(`${API}${endpoint}`, payload, config);
    return response.data;
  } catch (error) {
    const resp = {};
    return resp;
  }
};

function getAuthHeaders() {
  let jwtToken = localStorage.getItem('token');
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  };
  console.log('AthHeader', config);
  return config;
}


export default {
  getAllMenus: getAllMenus,
  getAllMenusLite: getAllMenusLite,
  saveMenu: saveMenu,
  saveMenuOrder: saveMenuOrder,
  getAppById: getAppById,
  getDashboardData: getDashboardData,
  deployChanges: deployChanges,
  deleteMenuByUniqueId: deleteMenuByUniqueId,
  createApp: createApp,
  updateApp: updateApp,
  getAllApps: getAllApps,
  cloneApp: cloneApp,
  doGet,
  doPost
};
