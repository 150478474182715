<template>
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center ">
      <a class="navbar-brand1 brand-logo ml-5" href="#">
        <img
          src="../../public/images/logo1.png"
          class="me-2"
          alt="logo"
          style="width: 80%"/>
      </a>
      <a class="btn btn-warning " href="https://docs.shortcodeafrica.com" target="_blank">
        <span class="font-weight-bold">Docs</span>
      </a>
    </div>


    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a
            class="nav-link"
            href="#"
            data-bs-toggle="dropdown"
            id="profileDropdown"
          >
            <strong> Hi, {{ user }}</strong>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
            <router-link class="dropdown-item text-white" to="/account-profile">
              <i class="fa fa-cog text-primary" style="margin-right: 10px"></i>
              Account 
            </router-link>
           
             
            <a class="dropdown-item text-white" @click="logout">
              <i
                class="fa fa-sign-out text-primary"
                style="margin-right: 10px"
              ></i>
              Logout
            </a>
          </div>
        </li>
        
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas">
        <span class="ti-layout-grid2"></span>
      </button>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      title: "Dashboard",
      user: {},
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    } 
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      
      this.$router.push("/login");
    } else {
      this.user = localStorage.getItem("user");
      localStorage.getItem("token");
    }
  },
};
</script>
