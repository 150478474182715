<template>
  <div>
    <transition name="fade">
      <div v-if="isModalShow" class="logsModal">
        <div class="modal-content">
          
          
          <h5 class="modal-title">
            <button @click="$router.go(-1)" class="btn btn-primary btn-sm">
            <i class="fas fa-arrow-left"></i>
            Back
          </button>
            Debug Output
          </h5>
          <div class="body-Body">
            <div class="inputContainer">
               <p>Dial Number</p>
               <p>
                <input class="numberInput form-control" v-model="mobileNumber"/>
               </p>
            </div>

            <div v-if="userInputs.length">
              <div class="header">
               <h4>User Inputs -  <small>session.inputs.</small></h4>
            </div>
            <div class="variableContainer">
              <div v-for="(input, index) in userInputs" :key="index" >
                <div class="variableContent">
                <p>{{input.fieldName}}</p>
                <p>{{input.fieldValue}}</p>
              </div>
              <hr class="divider">
              </div>
            </div>
            </div>
           

<br>
  <hr class="divider">
<br>

<div v-if="actionResults.length">
  <div class="header">
               <h4>Action Results  -  <small class="code">session.actionResults.</small></h4>
            </div>
            <div class="variableContainer">
              <div v-for="(input, index) in actionResults" :key="index" >
                <div class="variableContent">
                <p>{{input.actionName}} 
                  <small> - {{input.menuName}}</small>
                </p>
                <br>
                <span>{{input.actionResult}}</span>
                
              </div>
              <hr class="divider">
              </div>
            </div>
</div>
           
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import * as dateFns from 'date-fns';
import baseUrl from "@/shared/baseUrl";
import menuApi from "@/shared/menuApi";

export default defineComponent({
  name: "Debugger",
  data() {
    return {
      isModalShow:true,
      currentApp: {},
      pullInterval:2500,
      timeoutInputs :null,
      timeOutActionReults :null,
      unmounted:false,
      mobileNumber: "",
      userInputs :[],
      actionResults :[]
    }
  },
  watch: {
    mobileNumber: function(newMobileNumber, oldInputText) {
       this.$store.commit("setDialingPhone", newMobileNumber);
    }
  },
  methods: { 
    pollForNewInputs(){
     let vm=this;
     if(this.unmounted){
       return;
     }
     this.timeoutInputs = setTimeout(function (){
        vm.getSessionInputs(function (){
          console.log("Polling for new getSessionInputs>>")
          vm.pollForNewInputs();
        })
      },this.pullInterval)
    },
    pollForNewActionResults(){
     let vm=this;
     if(this.unmounted){
       return;
     }
     this.timeOutActionReults = setTimeout(function (){
        vm.getActionResults(function (){
          console.log("Polling for new actionResults>>")
          vm.pollForNewActionResults();
        })
      },this.pullInterval)
    },
    getSessionInputs(callback){
      let payload = {
        appId: this.currentApp.appId,
        sessionId:  this.$store.getters.getCurrentSessionId
      }
      if(payload.sessionId==null){
        return;
      }
       baseUrl
          .post(`/debug/userInputs`, payload).then((response) => {
            console.log("Response for inputs >>>>",response.data);
          if(response.data.status==="00"){
            this.userInputs=response.data.data;
          } 
          if(callback){
            callback();
          }
      }).catch((error) => {
        this.error = error;
        console.log("The was an error",error);
        if(callback){
          callback();
        }
      });
    },
    getActionResults(callback){
      let payload = {
        appId: this.currentApp.appId,
        sessionId: this.$store.getters.getCurrentSessionId
      }
      if(payload.sessionId==null){
        return;
      }
      console.log("the payload to get actionResults>>",payload);
      baseUrl
          .post(`/debug/actionResults`, payload).then((response) => {
            console.log("Response for actionResults >>>>",response.data);
        if(response.data.status==="00"){
          this.actionResults=response.data.data;
        } 
        if(callback){
          callback();
        }
      }).catch((error) => {
        this.error = error;
        console.log("The was an error",error);
        if(callback){
          callback();
        }
      });
    },
    async getCurrentApp(){
      this.mobileNumber= this.$store.getters.getDialingPhone;
      if(this.mobileNumber==null || this.mobileNumber==''){
        this.mobileNumber = localStorage.getItem('dialerMobile');
        this.$store.commit("setDialingPhone", this.mobileNumber);
      }
      console.log("the mobile number444>>>"+this.mobileNumber);
      let app = this.$store.getters.getCurrentApp;
      if (app == null || app.appId == null) {
         let response = await menuApi.getAppById(this.$route.params.appId);
         if (response.status == "00") {
          app = response.data;
        }
      }
      this.currentApp = app;
    },
  },
  async mounted() { 
    await this.getCurrentApp();
    await this.pollForNewInputs();
    await this.pollForNewActionResults();
    this.unmounted=false;
  },
  beforeUnmount() {
     clearTimeout(this.timeoutInputs);
    clearTimeout(this.timeOutActionReults);
    this.unmounted=true;
  }
})
</script>



<style scoped>
.logsModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: opacity 0.5s;
  animation: showPad 1s ease  1;
}
.modal-title{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.inputContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #fff;
  padding:20px;
}
.inputContainer p{
  font-size: 12px;
  color: #fff;
  margin: 0;
  font-weight: bold;
}
.numberInput{
  font-size: 12px;
  color: #fff;
  margin: 0;
  font-weight: bold;
  border: 1px solid #fff;
  padding: 5px 10px;
  border-radius: 2px;
}
.header{
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}
.variableContainer{
  border: 1px solid #fff;
}
.variableContent{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.divider{
  margin: 0;
  background-color: #fff;
  color: #fff;
}
@keyframes showPad {
  0% {
    top: 50px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  height: 100vh;
}

.body-Body{
  height: 100%;
  overflow-y: scroll;
  margin-top: 30px;
}
.body-Body p{
  font-size: 12px;
}

/* Transition effect */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>