<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">CALL A REST API</h4>
          <div class="input-group mb-0">
            <select name="" id="" v-model="restapi.method">
              <option value="">..Method..</option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
            </select>
            <input
              type="text"
              class="form-control"
              v-model="restapi.url"
              placeholder="Enter request URL"
            />
            <select id="" v-model="restapi.contentType">
              <option value="">Content Type</option>
              <option value="application/json">application/json</option>
              <option value="application/javascript">
                application/javascript
              </option>
              <option value="application/x-www-form-urlencoded">
                application/x-www-form-urlencoded
              </option>
              <option value="application/xml">application/xml</option>
              <option value="text/xml">text/xml</option>
              <option value="text/html">text/html</option>
            </select>
          </div>
          <label class="error mt-2 text-danger small" v-if="errors.request">
            {{ errors.request || '' }}
          </label>
          <div class="form-group" v-if="restapi.method !== 'GET'">
            <h4 class="card-title mt-4">
              REQUEST BODY
              <RestActionPopOver />
            </h4>
            <v-ace-editor
              v-model:value="restapi.payload"
              lang="json"
              theme="monokai"
              style="height: 300px"
            />
            <label class="error mt-2 text-danger" v-if="errors.payload">{{ errors.payload }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import RestActionPopOver from "@/components/popoverComponentRestApiAction";
export default {
  props: {
    id: "",
    restapi: {     
    },
  },
  data() {
    return {
      errors: {},
    };
  },
  components: {
    VAceEditor,
    RestActionPopOver,
  },
  methods: {
    addHeader(index) {
      if (!this.restapi.headers) {
        this.restapi.headers = [];
      }
      this.restapi.headers.push({});
    },
    removeHeader(index) {
      this.restapi.headers.splice(index, 1);
    },
    validateFields() {
      const { url, method, payload, contentType } = this.restapi;
      this.errors = {};
      if (!url) {
        this.errors.url = "Request URL is required.";
      }

      if (!method) {
        this.errors.method = "HTTP method is required.";
      }
      if (method !== 'GET') {
        if (contentType === 'application/json') {
          try {
            JSON.parse(payload);
          } catch (e) {
            this.errors.payload = "Request body must be valid JSON.";
          }
        }
        if (!payload) {
          this.errors.payload = "Request body is required for non-GET methods.";
        }
      }

      if (this.errors.url || this.errors.method || this.errors.contentType) {
        this.errors.request = `${this.errors.url || ''} ${this.errors.method || ''} ${this.errors.contentType || ''}`.trim();
      }

      return Object.keys(this.errors).length === 0;
    }
    
  },
  mounted(){
     this.restapi.method = "POST";
     this.restapi.contentType="application/json"
  }
};
</script>

<style lang="scss" scoped></style>
