<template>
      <div class="row">
                        <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                            <h4 class="card-title">Send Payment Prompt</h4>
                                <div class="row" >
                                <div class="col-md-12">
                                    <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Mobile Number</label>
                                    <div class="col-sm-8">
                                        <input
                                        type="text"
                                        class="form-control"
                                        v-model="paymentPrompt.mobile"
                                        placeholder="Payment Mobile"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Amount</label>
                                    <div class="col-sm-8">
                                        <input
                                        type="text"
                                        class="form-control"
                                        v-model="paymentPrompt.amount"
                                        placeholder="Debit Amount"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Network</label>
                                    <div class="col-sm-8">
                                        <input
                                        type="text"
                                        class="form-control"
                                        v-model="paymentPrompt.network"
                                        placeholder="Mobile Network"
                                        />
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
</template>

<script>
 export default {
    props: {
      paymentPrompt:{
          type:Object,
          default:{}
      }
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>

</style>