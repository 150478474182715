<template>
  <div class="card">
    <div class="card-header">
      <h4 style="padding: 10px">Account Settings</h4>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <ul
            class="nav nav-pills nav-pills-vertical nav-pills-info"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical">
            <li class="nav-item">
              <a
                class="nav-link"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                href="#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="false"
              >
                <i class="fa fa-cog"></i>
                Change Password
              </a>
            </li>
          </ul>
        </div>
        <div class="col-8">
          <div class="tab-content tab-content-vertical" id="v-pills-tabContent">
            <div
              class="tab-pane show fade active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab">
              <form class="pt-3" @submit.prevent="handleChangePassword">
                <div
                  :class="[alertCss]"
                  class="alert alert-dismissible fade show"
                  role="alert"
                  v-if="msg != ''">
                  <strong>{{ msg }}</strong>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-group row">
                  <label for="oldPassword" class="col-sm-4 col-form-label">Old Password</label>
                  <div class="col-sm-8">
                    <input
                      type="password"
                      id="oldPassword"
                      name="password"
                      class="form-control form-control-lg"
                      v-model="changePassword.oldPassword"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="newPassword" class="col-sm-4 col-form-label">New Password</label>
                  <div class="col-sm-8">
                    <input
                      type="password"
                      id="newPassword"
                      name="password"
                      class="form-control form-control-lg"
                      v-model="changePassword.newPassword"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="confirmPassword" class="col-sm-4 col-form-label">Confirm Password</label>
                  <div class="col-sm-8">
                    <input
                      type="password"
                      id="confirmPassword"
                      name="cpassword"
                      class="form-control form-control-lg"
                      v-model="changePassword.confirmPassword"
                    />
                  </div>
                </div>
                <div class="form-group row mt-3">
                  <div class="col-sm-8 offset-sm-4">
                    <button
                      type="submit"
                      class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-white"
                      style="background-color: #1e1e2f; border: none"
                    >
                      CHANGE PASSWORD
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";

export default {
  name: "AccountProfile",
  components: {},
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      email: "",
      alertCss: "alert-warning",
      changePassword: {},
      msg: "",
    };
  },

  methods: {
    handleChangePassword() {
        if (this.validateFields()) {
            const payload = {
                password: this.changePassword.oldPassword,
                newPassword: this.changePassword.newPassword,
            };
            api.doPost('/api/changePassword', payload)
                .then(response => { 
                  console.log("Password change response>>",response.data);
                    if (response.status == "00") {
                        this.msg = "Password changed successfully.";
                        this.alertCss = "alert-success";
                        this.changePassword={}
                    } else {
                        this.msg = response.message || "Failed to change password.";
                        this.alertCss = "alert-danger";
                    }
                })
                .catch(error => {
                    this.msg = "An error occurred. Please try again!! "+error;
                    this.alertCss = "alert-danger";
                });
        }
    },
    validateFields() {
      const { oldPassword, newPassword, confirmPassword } = this.changePassword;

      if (!oldPassword) {
        this.msg = "Old password is required.";
        this.alertCss = "alert-danger";
        return false;
      }
      if (!newPassword || newPassword.length < 6) {
        this.msg = "New password must be at least 6 characters long.";
        this.alertCss = "alert-danger";
        return false;
      }
      if (newPassword !== confirmPassword) {
        this.msg = "New password and confirmation do not match.";
        this.alertCss = "alert-danger";
        return false;
      }

      this.msg = ""; // Clear message if validation passes
      this.alertCss = "alert-warning"; // Reset alert style
      return true; // Validation successful
    },
  },
};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}

#error {
  margin-top: 40px;
}
</style>
