<template>
  <div class="container" style="padding: 70px">
    <div class="row">
      <div class="col-lg-8 col-md-8">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div>
              <h3>Contact Us</h3>
              <form class="pt-3">
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control form-control-lg"
                    placeholder="Email"
                    v-model="contact.email"
                  />
                  <p class="text-danger" v-show="error.email">
                    {{ error.email }}
                  </p>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Mobile"
                    v-model="contact.mobile"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Subject"
                    v-model="contact.subject"
                  />
                  <p class="text-danger" v-show="error.subject">
                    {{ error.subject }}
                  </p>
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control form-control-lg"
                    cols="10"
                    rows="20"
                    placeholder="Message"
                    v-model="contact.message"
                  />
                  <p class="text-danger" v-show="error.message">
                    {{ error.message }}
                  </p>
                </div>
                <div class="mt-3">
                  <button
                    type="button"
                    class="btn btn-primary float-right text-white"
                    style="width: 200px"
                    @click="submit()"
                  >
                    Send
                  </button>
                </div>
              </form>
            

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <h4>Ghana Office</h4>         
              <p>Phone : +233534630196, +233509610011</p>
              <p>WhatsApp: +233534630196</p>
              <p>Block 14, Nii Sai Road, East Legon</p>
              <p>Email : info@shortcodeafrica.com</p>              
          </div>
          <p></p>
          <hr>
          <p></p>
          <div class="row">
            <h4>Nigeria Office</h4>
            <p>Phone : +2349068076227, +233246583910</p>
            <p>WhatsApp: +2349068076227</p>
            <p>Email : info@shortcodeafrica.com</p>
            <p></p>
            <p>
              52 Tai Solarin Ave, Gwarinpa Estate 900108, Abuja, Federal Capital Territory, Nigeria
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
   
  </div>
</template>
<style scoped>
p {
  position: inherit !important;
}
</style>

<script>
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      contact: {},
      error: {},
    };
  },
  methods: {
    submit() {
      if (this.validateFields()) {
        let payload = Object.assign(this.contact);
        api
          .doPost("/api/contactus", payload)
          .then((response) => {
            console.log("Response from server >>", response);
            if (response.status == "00") {
              this.contact = {};
              this.toast.success(response.message);
            } else {
              this.toast.error("Failed to send contact info");
            }
          })
          .catch((error) => {
            this.toast.error(error.message);
          });
      }
    },
    validateFields() {
      this.error = {};
      if (!this.contact.email) {
        this.error.email = "Email is required";
      }
      if (this.contact.email && this.contact.email.indexOf("@") == -1) {
        this.error.email = "Invalid email";
      }
      if (!this.contact.subject) {
        this.error.subject = "Subject is required";
      }
      if (!this.contact.message) {
        this.error.message = "Message is required";
      }
      return Object.keys(this.error).length == 0;
    },
  },
};
</script>

<style scoped>
.nigerOffice {
  margin-top: 50px;
}
</style>
