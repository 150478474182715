<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-0">
            WRITE JAVASCRIPT
            <JsActionPopOver />
          </h4>
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="home-1"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
            <label class="error text-danger small mt-0" v-if="errors.javascript"  >
             {{errors.javascript}}
                </label>
              <div class="form-group ">
               
                <v-ace-editor
                  v-model:value = "javascript.code"
                  :options="{ fontFamily: 'monospace' }"
                  lang="javascript"
                  theme="monokai"
                  style="height: 300px"
                />
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import PopOver from "@/components/popoverComponent";
import JsActionPopOver from "@/components/popoverComponentJavascriptAction";
import { Parser } from "acorn";  
import api from "@/shared/menuApi";

export default {
  props: {
    javascript: {
      type: Object,
      default: {},
    },
  },
  data(){
    return {
      errors:{}
    }
  },
  components: {
    VAceEditor,
    PopOver,
    JsActionPopOver,
  },
  mounted() {
     
    setTimeout(() => {
      if (!this.javascript.code) {
            api
            .doGet(`/api/docs/javascript`)
            .then((data) => {
              console.log("the sdk documentaiton >>"+data)
              if (data) {
                this.javascript.code = data;
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }
        }, 1000); 
  
  },
  methods: {
    validateFields() {
      const code = this.javascript.code;
      this.errors = {};
      if (!code) {
        this.errors.javascript = "Enter javascript code";
        return false ;
      }
      try {
        Parser.parse(code, { ecmaVersion: 2020 });
        console.log("Javascript code parsed correctly")
        return true;
      } catch (error) {
        console.log("acorn error "+JSON.stringify(error.loc))
        this.errors.javascript = error.message + ", "+JSON.stringify(error.loc) ;
        return false;
      }
    },
  },
};
</script>

<style lang="css">
.ace_editor,
.ace_editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas",
    monospace !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}
</style>
