<template>
  <div>
    <div class="row" v-if="currentAction">
      <div class="row">
        <div class="col-sm-9">
          <div class="card rounded border mb-2">
            <div class="card-body p-0">
              <div class="media pl-3">
                <i
                  class="fa-solid handle fa-up-down-left-right icon-sm align-self-center me-3 fa fa-arrows text-primary header"
                  style="cursor: move"
                ></i>
                <div class="media-body">
                  <div
                    class="btn btn-primary"
                    style="
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                      background-color: #27293f;
                      border: none;
                      color: #fff;">
                    <div class="bold" style="height: 25px; line-height: 30px">
                      <span class="label h5">{{
                        currentAction.inheritsPlugin
                      }}</span>
                      : <span> {{ currentAction.actionName }}</span>
                    </div>
                    <span>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mr-1 text-white disabled not-allowed">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm text-white disabled not-allowed">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div style="height: 25px; line-height: 55px">
            <div class="row">
              <div class="col-10">
                <button
                  class="btn btn-outline-primary mr-5"
                  tooltip="Done Editting"
                  @click="doneEditing()"
                  v-show="editIndex > -1"
                >
                  <strong> Save </strong>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <button
                  class="btn btn-outline-primary updateBtn"
                  tooltip="Save"
                  @click="saveAction()"
                  v-show="editIndex == -1"
                >
                  <strong> Save </strong>
                </button>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-outline-danger btn-sm updateBtn"
                  tooltip="Cancel"
                  @click="cancelSave()"
                  v-show="editIndex == -1"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <label class="col-sm-5 col-form-label">Action Name
                  <ToolTip
                    content="The result of this action can be referenced in other actions using the action name."
                  />
                  :
                </label>
                <div class="col-sm-7">
                  <input
                    placeholder="No spaces"
                    type="text"
                    class="form-control form-control-sm"
                    v-model="currentAction.actionName"
                  />
                  <label class="error mt-2 text-danger" v-if="errors.actionName">{{ errors.actionName }}</label>
                </div>
              </div>
            </div>
            <div class="col-sm-6 float-right">
              <div class="form-group row">
                <div class="col-sm-12 float-right">
                  <ul
                    class="nav nav-tabs"
                    role="tablist"
                    style="border: none; padding: 0px"
                  >
                    <div
                      class="form-group button"
                      id="buttons-0"
                      style="border: none; padding: 0px"
                    >
                      <div class="btn-group" style="border: none; padding: 0px">
                        <button
                          type="button"
                          class="btn btn-outline-secondary dropdown-toggle"
                          style="padding: 15px"
                          data-bs-toggle="dropdown">
                          <span>{{ currentAction.actionTypeLabel }}</span>
                        </button>
                        <div class="dropdown-menu">
                          <a
                            class="dropdown-item text-white"
                            @click="setPluginType(0, 'javascript')">Javascript</a>
                          <a class="dropdown-item text-white" @click="setPluginType(0, 'restapi')">Rest Call</a>
                          <a
                            class="dropdown-item text-white"
                            @click="setPluginType(0, 'dbaccess')"
                            >Database</a>
                          <a class="dropdown-item text-white" @click="setPluginType(0, 'sendsms')">Send Sms</a>
<!--                          <a-->
<!--                            class="dropdown-item text-white"-->
<!--                            @click="setPluginType(0, 'paymentprompt')"-->
<!--                            >Payment Prompt</a-->
<!--                          >-->
<!--                          <a-->
<!--                            class="dropdown-item text-white"-->
<!--                            @click="setPluginType(0, 'customAction')"-->
<!--                            >Custom Action Code</a-->
<!--                          >-->
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="actionType"
                      value="pre"
                      v-model="currentAction.actionType" />
                    Call Action Before Showing Menu
                    <i class="input-helper"></i
                  ></label>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="actionType"
                      v-model="currentAction.actionType"
                      value="post" />
                    Call Action After Showing Menu
                    <i class="input-helper"></i
                  ></label>
                </div>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="tab-content" style="border: none">
              <transition name="slide-fade">
                <div v-if="currentAction.inheritsPlugin == 'javascript'">
                  <JavascriptActionComponent
                    :javascript="currentAction.javascript"
                    ref="javascriptAction" 
                  />
                </div>
              </transition>

              <transition name="slide-fade">
                <div v-if="currentAction.inheritsPlugin == 'restapi'">
                  <RestApiActionComponent
                    :restapi="currentAction.restapi"
                    :id="currentAction.id"
                    ref="restApiAction" 
                  />
                </div>
              </transition>

              <transition name="slide-fade">
                <div v-if="currentAction.inheritsPlugin == 'dbaccess'">
                  <DatabaseActionComponent :dbaccess="currentAction.dbaccess" />
                </div>
              </transition>
              <transition name="slide-fade">
                <div v-if="currentAction.inheritsPlugin == 'sendsms'">
                  <SendSmsActionComponent :sendSms="currentAction.sendSms" />
                </div>
              </transition>
              <transition name="slide-fade">
                <div v-if="currentAction.inheritsPlugin == 'paymentprompt'">
                  <PaymentPromptActionComponent
                    :paymentPrompt="nav.paymentPrompt"
                  />
                </div>
              </transition>
              <transition name="slide-fade">
                <div v-if="currentAction.inheritsPlugin == 'customAction'">
                  <CustomerActionComponent
                    :customAction="currentAction.customAction"
                  />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>

    <div v-if="editMode == false">
      <draggable
        v-model="actions"
        group="action"
        @start="drag = true"
        @end="drag = false"
        item-key="index"
         handle=".handle"
        tag="transition-group"
        :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
        v-bind="dragOptions"
      >
        <template #item="{ element, index }" :key="index">
          <div class="row">
            <div class="col-md-10">
              <div class="card rounded border mb-2">
                <div class="card-body p-0">
                  <div class="media pl-3">
                    <i class="fa-solid handle fa-up-down-left-right icon-sm align-self-center me-3 fa fa-arrows text-primary header"
                      style="cursor: move"></i>
                    <div class="media-body">
                      <div 
                        class="btn btn-primary"
                        style="
                          display: flex;
                          justify-content: space-between;
                          width: 100%;
                          background-color: #27293f;
                          border: none;
                          color: #fff;
                        ">
                        <div
                          class="bold"
                          style="height: 25px; line-height: 30px">
                          <span class="label h5">{{element.inheritsPlugin}}</span>
                          : <span> {{ element.actionName }}</span>
                        </div>
                        <span>
                          <button
                            type="button"
                            class="btn btn-primary btn-sm mr-1 text-white"
                            @click="editAction(index)">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger btn-sm text-white"
                            @click="removeAction(index)"
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2" style="height: 25px; line-height: 50px">
              <button
                class="btn btn-primary btn-sm"
                style="margin-right: 20px; color: #fff"
                @click="addAction(i)"
              >
                <b> + </b>
              </button>
            </div>
          </div>
        </template>
      </draggable>

      <button
        class="btn btn-primary btn-sm"
        @click="addAction(-1)"
        style="margin-right: 20px; color: #fff"
      >
        <b> + </b>
      </button>
      <br />
    </div>
  </div>
</template>
<script>
import Editor from "./editorComponent.vue";
import ToolTip from "@/components/tooltipComponent.vue";
import JavascriptActionComponent from "@/components/actionJavascriptComponent.vue";
import RestApiActionComponent from "@/components/actionRestapiComponent.vue";
import DatabaseActionComponent from "@/components/actionDatabaseComponent.vue";
import SendSmsActionComponent from "@/components/actionSendSmsComponent.vue";
import PaymentPromptActionComponent from "@/components/actionPaymentPromptComponent.vue";
import CustomerActionComponent from "@/components/actionCustomCodeComponent.vue";
import draggable from "vuedraggable";
export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  components: {
    Editor,
    JavascriptActionComponent,
    RestApiActionComponent,
    DatabaseActionComponent,
    SendSmsActionComponent,
    PaymentPromptActionComponent,
    CustomerActionComponent,
    ToolTip,
    draggable,
  },
  data() {
    return {
      headers: [],
      actions: [],
      actionTypeLabel: "..Select Action Type..",
      actionTypeLabels: {
        restapi: "Rest Api",
        dbaccess: "Database Query",
        javascript: "Javascript",
        sendsms: "Send Sms",
        paymentprompt: "Payment Prompt",
        customAction: "Custom Code Action",
      },
      errors:{},
      editMode: false,
      currentAction: null,
      insertIndex: -1,
      editIndex: -1,
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      }
    },
    itemKeyFunc(){
      return "p-"+this.generateUniqueId();
    }
  },
  methods: {
    addAction(index) {
      this.insertIndex = index;
      this.editIndex = -1;
      this.currentAction = {
        id: this.generateUniqueId(),
        javascript: { code: "" },
        restapi: {
          headers: [],
          basicAuth: {},
          method: "POST",
          contentType: "application/json",
          payload: "",
        },
        dbaccess: { dataSourceType: "remote", query: "", dialect:"" },
        sendSms: {},
        paymentPrompt: {},
        customAction: { code: "" },
        actionType: "post",
        actionTypeLabel: "..Select Action Type..",
      };
      this.editMode = true;
    },
    saveAction() {
      if(!this.validateFields()){
        return;
      }
      const action = Object.assign({}, this.currentAction);
      if (this.insertIndex == -1) {
        this.actions.push(action);
      } else {
        console.log("The insert Index >>", this.insertIndex);
        this.actions.splice(this.insertIndex, 0, action);
      }
      this.currentAction = null;
      this.insertIndex = -1;
      this.editIndex = -1;
      this.editMode = false;
    },
    cancelSave() {
      this.currentAction = null;
      this.insertIndex = -1;
      this.editIndex = -1;
      this.editMode = false;
    },
    editAction(index) {
      this.currentAction = this.actions[index];
      this.editMode = true;
      this.editIndex = index;
      this.insertIndex = -1;
    },
    doneEditing() {
      if(!this.validateFields()){
        return;
      }
      this.editIndex = -1;
      this.insertIndex = -1;
      this.currentAction = null;
      this.editMode = false;
    },
    generateUniqueId() {
      var date = new Date();
      var chars = "0123456789";
      var randomstring = "";
      for (var i = 0; i < 2; i++) {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
      }
      return randomstring + "" + date.getMilliseconds();
    },
    removeAction(index) {
      this.actions.splice(index, 1);
    },
    buildPayload() {
      const array = [];
      for (let k = 0; k < this.actions.length; k++) {
        const payload = {
          actionName: this.actions[k].actionName,
          actionType: this.actions[k].actionType,
          inheritsPlugin: this.actions[k].inheritsPlugin,
        };
        payload.code = "";
        if (this.actions[k].inheritsPlugin == "javascript") {
          payload.code = this.actions[k].javascript.code;
        } else if (this.actions[k].inheritsPlugin == "restapi") {
          payload.code = Object.assign({}, this.actions[k].restapi);
        } else if (this.actions[k].inheritsPlugin == "dbaccess") {
          payload.code = Object.assign({}, this.actions[k].dbaccess);
        } else if (this.actions[k].inheritsPlugin == "customAction") {
          payload.inheritsPlugin = this.actions[k].customAction.inheritsPlugin;
          payload.code = this.actions[k].customAction.code;
        } else if (this.actions[k].inheritsPlugin == "sendsms") {
          payload.code = Object.assign({}, this.actions[k].sendSms);
        } else if (this.actions[k].inheritsPlugin == "paymentprompt") {
          payload.code = Object.assign({}, this.actions[k].paymentPrompt);
        }
        array.push(payload);
      }
      return array;
    },
    getActions() {
      return this.buildPayload();
    },
    setPluginType(index, pluginType) {
      this.currentAction.inheritsPlugin = pluginType;
      this.currentAction.actionTypeLabel = this.actionTypeLabels[pluginType];
    },
    dataBaseType(index) {
      if (index.target.value == "local") {
        $("#dbSelect").hide();
      } else {
        $("#dbSelect").show();
      }
    },
    validateFields() {
      const actionName = this.currentAction.actionName;
      this.errors = {}; // Create an errors object for actionName

      if (!actionName) {
        this.errors.actionName = "Action name is required."; // Set error if empty
      } else if (!/^[a-zA-Z_$][0-9a-zA-Z_$]*$/.test(actionName)) {
        this.errors.actionName = "Action name must be a valid JavaScript identifier."; // Set error if not valid
      }
      // Validate based on inheritsPlugin type
      if (this.currentAction.inheritsPlugin) {
        if (this.currentAction.inheritsPlugin === 'javascript') {
          const pluginRef = this.$refs.javascriptAction;
          if (pluginRef && typeof pluginRef.validateFields === 'function') {
            const pluginValid = pluginRef.validateFields(); // Call the validate method on the JavascriptActionComponent
            if (!pluginValid) {
              this.errors.plugin = "Validation failed in the Javascript action component."; // Set error if validation fails
            }
          }
        } else if (this.currentAction.inheritsPlugin === 'restapi') {
          const pluginRef = this.$refs.restApiAction;
          if (pluginRef && typeof pluginRef.validateFields === 'function') {
            const pluginValid = pluginRef.validateFields(); // Call the validate method on the RestApiActionComponent
            if (!pluginValid) {
              this.errors.plugin = "Validation failed in the Rest API action component."; // Set error if validation fails
            }
          }
        }
      }
      // Check if errors object is empty
      if (Object.keys(this.errors).length === 0) {
        return true; // Valid
      }
      return false; // Invalid
    },
  },
  mounted() {
    for (let k = 0; k < this.data.length; k++) {
      let item = Object.assign({}, this.data[k]);
      console.log("The single action is>>" + JSON.stringify(item));
      let action = {};
      action.id = this.generateUniqueId();
      let pluginType = item.inheritsPlugin;
      action.inheritsPlugin = pluginType;
      action.actionTypeLabel = this.actionTypeLabels[pluginType];
      action.actionName = item.actionName;
      action.actionType = item.actionType;
      action.restapi = { payload: "" };
      action.javascript = { code: "" };
      action.dbaccess = { query: "" };
      action.sendSms = {};
      action.paymentPrompt = {};
      action.customAction = { code: "" };
      action.restapi.basicAuth = {};
      if (
        action.inheritsPlugin !== "javascript" &&
        item.code.indexOf("function") == 0
      ) {
        action.inheritsPlugin = "customAction";
        action.customAction = {
          code: item.code,
          inheritsPlugin: item.inheritsPlugin,
        };
        action.actionTypeLabel = this.actionTypeLabels["customAction"];
        this.actions.push(action);
        continue;
      }
      if (action.inheritsPlugin !== "javascript") {
        item.code = JSON.parse(item.code);
      }
      if (pluginType === "restapi") {
        action.restapi.basicAuth = item.code.basicAuth;
        action.restapi.headers = item.code.headers;
        action.restapi.method = item.code.method;
        action.restapi.url = item.code.url;
        action.restapi.payload = item.code.payload;
        action.restapi.contentType = item.code.contentType;
      } else if (pluginType === "dbaccess") {
        action.dbaccess = item.code;
      } else if (pluginType === "javascript") {
        action.javascript.code = item.code;
      } else if (pluginType === "sendsms") {
        action.sendSms = item.code;
      } else if (pluginType === "paymentprompt") {
        action.paymentPrompt = item.code;
      }
      this.actions.push(action);
    }
   },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.not-allowed {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
/*******vuedraggable transition**** */
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 3s;
}
.ghost {
  opacity: 0.5;
  background: #264350;
}
</style>
