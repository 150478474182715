<template>
  <div class="card">
    <div class="card-header">
      <h4 style="padding: 10px">Provision ShortCode Extension</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 col-sm-12"></div>
        <div class="col-md-9 col-sm-12">
          <form>
            <button
              type="button"
              class="btn btn-primary mt-0 text-white float-right"
              @click="openCreateExtensionModal()">
              New Extension
            </button>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 col-sm-12"></div>
        <div class="col-md-10 col-sm-12">
          <div class="form-group row mt-5" v-if="availableExtensions.length">
            <h4>Available Shortcode Extensions</h4>
            <div class="table-responsive">
              <table id="order-listing" class="table">
                <thead>
                  <tr>
                    <th>Short Code</th>
                    <th>Provider</th>
                    <th>Http Method</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in availableExtensions" :key="data.uuid">
                    <td>
                      {{ data.shortCode }}
                    </td>
                    <td>{{ data.provider }}</td>
                    <td>{{ data.requestMethod }}</td>
                    <td>{{ data.status }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-outline-success ml-2 design"
                        @click="openProvisionModal(data)"
                        title="Select ShortCode"
                      >
                        <i class="fa fa-external-link"></i> Provision ShortCode
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger ml-2 design"
                        :disabled="data.selected"
                        @click="deleteExtension(data)"
                        title="Select ShortCode"
                      >
                        <i class="fa fa-trash" tooltip="Delete"></i> Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group" v-if="availableExtensions.length == 0">
            <div class="card card-inverse-info">
              <div class="card-body">
                <div class="card-title font-weight-bold">No ShortCodes</div>
                <div class="card-text">
                  <div class="row">
                    <div class="col-1">
                      <div class="badge badge-pill badge-outline-info">
                        <i class="fas fa-info-circle"></i>
                      </div>
                    </div>
                    <div class="col-11">
                      <span class="small">
                        There are no provisioned shortcode extensions
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="modal fade"
        id="provisionShortCodeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Provision Extension</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group row">
                  <label class="col-6">Provision for this Account : </label>
                  <span class="col-6">
                    <input
                      type="checkbox"
                      v-model="provision.self"
                      class="form-control-check"
                    />
                  </span>
                </div>
                <div class="form-group row" v-if="provision.self == false">
                  <label class="col-6">Account Email :</label>
                  <span class="col-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="provision.userEmail"
                    />
                  </span>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success text-white"
                @click="provisionShortCode()"
              >
                Submit
              </button>
              <button
                type="button"
                class="btn btn-light"
                @click="closeProvisionModal()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="createShortCodeExtension"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">
                Create ShortCode Extension
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="forms-sample">
                <div class="form-group row" v-if="errorMsg.length">
                  <div class="col-12">
                    <div
                      class="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong v-bind:key="err" v-for="err in errorMsg">{{
                        err
                      }}</strong>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label"
                    >Enter ShortCode Extension</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="*789*11#"
                      v-model="purchase.shortcode"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label">ShortCode Provider</label>
                  <div class="input-group">
                    <select
                      class="form-control"
                      style="
                        background-color: #2b2e4c;
                        color: #e9e6e6;
                        border: 1px solid #424351;
                      "
                      v-model="purchase.provider"
                    >
                      <option value="">..Select Provider..</option>
                      <option value="mtngh">MTN</option>
                      <option value="airtelgh">AirtelTigo/AT</option>
                      <option value="vodagh">Vodafone</option>
                      <option value="other">..Other Provider..</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row" v-if="purchase.provider == 'other'">
                  <label class="col-form-label"
                    >Enter provider tranlator name E.g. mtngh,
                    airtelgh,vodagh</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="mtngh"
                      v-model="purchase.otherprovider"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label">Http Request Method</label>
                  <div class="input-group">
                    <select
                      class="form-control"
                      style="
                        background-color: #2b2e4c;
                        color: #e9e6e6;
                        border: 1px solid #424351;
                      "
                      v-model="purchase.method"
                    >
                      <option value="">..Select Method..</option>
                      <option value="POST">POST</option>
                      <option value="GET">GET</option>
                      <option value="PUT">PUT</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success text-white"
                @click="createShortCodeExtension()"
              >
                Submit
              </button>
              <button
                type="button"
                class="btn btn-light"
                @click="closeCreateExtensionModal()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="prodgressDialog"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="loader-demo-box1">
              <div class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";

function openInNewTab(url) {
  window.open(url, "_blank", "noreferrer");
}
export default {
  name: "ProvisionShortCodeExtension",
  components: {},
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      purchase: {
        shortcode: "",
        provider: "",
        otherprovider: "",
        method: "",
      },
      errorMsg: [],
      availableExtensions: [],
      provision: {
        userEmail: "",
        self: true,
        extensionUuid: "",
      },
    };
  },
  methods: {
    openCreateExtensionModal() {
      jQuery("#createShortCodeExtension").modal("show");
    },
    closeCreateExtensionModal() {
      jQuery("#createShortCodeExtension").modal("hide");
    },
    confirmPurchase() {
      if (this.validateFields()) {
        jQuery("#confirmShortCodePurchaseModal").modal("show");
        if (this.purchase.chooseExtension) {
          this.purchase.shortCode = `${this.purchase.shortCode}${this.purchase.extension}#`;
        }
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    createShortCodeExtension() {
      if (!this.validateFields()) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      let payload = {
        shortCode: this.purchase.shortcode,
        provider: this.purchase.provider,
        otherprovider: this.purchase.otherprovider,
        method: this.purchase.method,
      };
      jQuery("#createShortCodeExtension").modal("hide");
       api
        .doPost("/api/subscription/createShortCode", payload)
        .then((response) => {
           if (response.status == "00") {
            this.toast.success(response.message);
            this.getAvailableShortCodes();
          } else {
            this.toast.info(response.message);
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    provisionShortCode() {
      const payload = {
        extensionUuid: this.provision.extensionUuid,
        userEmail: this.provision.userEmail,
        self: this.provision.self,
      };
      jQuery("#prodgressDialog").modal("show");
      api
        .doPost("/api/subscription/provisionShortCode", payload)
        .then((response) => {
          console.log("Response from server >>", response);
          jQuery("#prodgressDialog").modal("hide");
          if (response.status == "00") {
            this.toast.success(response.message);
            jQuery("#provisionShortCodeModal").modal("hide");
          } else {
            this.toast.error(response.message);
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    openProvisionModal(data) {
      this.provision = {
        userEmail: "",
        self: true,
        extensionUuid: data.uuid,
      };
      jQuery("#provisionShortCodeModal").modal("show");
    },
    closeProvisionModal() {
      jQuery("#provisionShortCodeModal").modal("hide");
    },
    getAvailableShortCodes() {
      let vm = this;
      api
        .doGet(`/api/getAllAvailableShortCodes`)
        .then((response) => {
          vm.availableExtensions = response;
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    deleteExtension(extension) {
      let uuid = extension.uuid;
      if (
        !confirm(`Are you sure you want to delete this extension ${extension.shortCode}`)
      ) {
        return;
      }
      api
        .doGet(`/api/deleteShortCode/${uuid}`)
        .then((response) => {
          if (response.status === "00") {
            this.toast.success(response.message);
            this.getAvailableShortCodes();
          } else {
            this.toast.error(response.message);
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    validateFields() {
      this.errorMsg = [];
      if (this.purchase.method === "") {
        this.errorMsg.push("Select a country");
      }
      if (this.purchase.provider === "") {
        this.errorMsg.push("Select shortcode provider ");
      }
      if (this.purchase.shortcode === "") {
        this.errorMsg.push("Enter USSD extension ");
      }
      if (this.purchase.method === "") {
        this.errorMsg.push("Select http method");
      }
      if (this.purchase.provider == "other") {
        if (this.purchase.otherprovider === "") {
          this.errorMsg.push("Enter other provider name");
        }
      }
      return this.errorMsg.length === 0;
    },
  },
  mounted() {
    this.getAvailableShortCodes();
  },
};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}

#error {
  margin-top: 40px;
}
</style>
